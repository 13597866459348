import classNames from 'classnames';

import Head from '@components/Head';
import { Header, Footer } from '@components/index';

import styles from './CommonLayout.module.scss';
const containerClass = 'container-wide';

interface Props {
  children: JSX.Element;
  headerComponent?: React.ComponentType<{ className?: string }>;
  footerComponent?: React.ComponentType<{ className?: string }>;
  isFooter?: boolean;
  isHeaderFullWidth?: boolean;
}

export default function CommonLayout({
  children,
  headerComponent: HeaderComponent = Header,
  isFooter = true,
  footerComponent: FooterComponent = Footer,
}: Props) {
  return (
    <div className={classNames(styles.root)}>
      <Head />

      <HeaderComponent className={classNames(styles.header, containerClass)} />

      <main className={styles.main}>{children}</main>

      {isFooter ? <FooterComponent /> : null}
    </div>
  );
}
