import { useState, useRef, useMemo } from 'react';

import cn from 'classnames';

import { getHours } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import Input from '@components/Input';
import { CalendarIcon } from '@components/Icon';
import useOnClickOutside from '@hooks/useOnClickOutside';
import { Slots } from '@graphql/generated';
import { isServer } from '@constants/index';
import DateChooserContent from '@components/DateChooser';

import { TODAY_RESETED_TIME } from './constants';
import s from './DateChooser.module.scss';

interface Props {
  placeholderText?: string;
  value?: Date;
  onChange: (value: Date, slotId?: Slots['id']) => void;
  isError?: boolean;
  loadingSlots?: boolean;
  // TODO: create interface
  slots: {
    __typename?: 'slots' | undefined;
    id: string;
    date: any;
    label: string;
    type: string;
    slotDayId: string;
    isActive: boolean;
    isValid: boolean;
  }[];
  slotId?: string;
  onGetSlots: (date: Date) => Promise<void>;
}

const DateChooser = ({
  onGetSlots,
  slots,
  loadingSlots,
  slotId = '',
  placeholderText = 'Дата и время',
  value,
  onChange,
  ...props
}: Props) => {
  const [activeModal, setActiveModal] = useState<boolean>(false);

  const divRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(divRef, !isServer ? handleCloseModal : () => null);

  function handleCloseModal() {
    setActiveModal(false);
  }

  const handleOpenModal = () => {
    setActiveModal(true);
    const slotDate = new Date(value || TODAY_RESETED_TIME);
    slotDate.setHours(0);
  };

  const formatedDate = useMemo(() => {
    if (!value) {
      return;
    }

    const newValue = new Date(value);
    const resetedDate = getHours(newValue) === 0;
    return resetedDate
      ? formatInTimeZone(newValue, 'Europe/Moscow', 'dd.MM.yyyy')
      : formatInTimeZone(newValue, 'Europe/Moscow', 'dd.MM.yyyy HH:mm');
  }, [value]);

  const handleSubmit = (date: Date, timeId: string) => {
    onChange(date, timeId);
    handleCloseModal();
  };

  return (
    <div className={s.root} ref={divRef}>
      <div className={s.input}>
        <CalendarIcon />

        <Input
          {...props}
          label={placeholderText}
          labelClassName={s.label}
          onFocus={handleOpenModal}
          onClick={handleOpenModal}
          value={formatedDate}
          onChange={() => null}
          readOnly
        />
      </div>

      <div className={cn(s.modal, activeModal && s.active)}>
        <DateChooserContent
          selectedDate={value}
          slots={slots}
          selectedSlotId={slotId}
          isLoadingSlots={loadingSlots}
          onSubmit={handleSubmit}
          onGetSlots={onGetSlots}
        />
      </div>
    </div>
  );
};

export default DateChooser;
