import Title from '@components/Title';
import Text from '@components/Text';
import { Button } from '@components/index';
import { HamburgerIcon } from '@components/Icon';
import Link from 'next/link';

import SearchForm from '../SearchForm/SearchForm';
import styles from './HeroSection.module.scss';

const HeroSection = () => (
  <div className={styles.root}>
    <div className={styles.mainContent}>
      <Title className={styles.title} level={1}>
        Анализы на дому
      </Title>
      <Text className={styles.text} size={'extra-large'}>
        {
          'Приезжаем к Вам в течение 2 часов после заказа или точно ко времени в любую точку Москвы.\nБережно делаем взятие биоматериала и передаем в лаборатории.\nРезультаты пришлем на почту и в личный кабинет, как только будут готовы.'
        }
      </Text>
    </div>

    <div className={styles.searchForm}>
      <SearchForm />
    </div>

    <Link href="/search" passHref>
      <Button className={styles.allResearches} theme="secondary" icon={<HamburgerIcon />}>
        <Text inline>Все исследования</Text>
      </Button>
    </Link>
  </div>
);

export default HeroSection;
