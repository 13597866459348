import Head from 'next/head';

const HeadComponent = () => {
  function jsonLd() {
    return {
      __html: `{
              "@context": "https://schema.org/",
              "name": "ЛабКлик – анализы на дому",
              "description": "ЛабКлик – цифровой медицинский сервис, объединяющий крупные лабораторные сети в Москве.", 
            }`,
    };
  }
  return (
    <Head>
      <title>LabClick</title>
      <meta property="og:title" content="ЛабКлик – анализы на дому" />
      <meta
        property="og:description"
        content="ЛабКлик – цифровой медицинский сервис, объединяющий крупные лабораторные сети в Москве."
      />
      <meta property="og:image" content={`${process.env.SITE_URL || 'https://www.labclick.ru'}/og-image.jpg`}></meta>
      <meta name="description" content="Labclick" />
      {/*<meta name="viewport" content="height=device-height, initial-scale=1, width=device-width, initial-scale=1" />*/}
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta content="true" name="HandheldFriendly" />
      <meta content="width" name="MobileOptimized" />
      <meta content="yes" name="apple-mobile-web-app-capable" />
      <script type="application/ld+json" dangerouslySetInnerHTML={jsonLd()} key="jsonld" />
    </Head>
  );
};

export default HeadComponent;
