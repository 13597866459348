import { Controller, Control, FieldError } from 'react-hook-form';

import { Button, SearchBox } from '@components/index';
import Text from '@components/Text';
import DateChooser from '@modules/home/components/DateChooser';
import { CheckoutWizardValues } from '@modules/checkout/CheckoutWizardContext';
import { Product, Slots } from '@graphql/generated';

import styles from './DesktopSearchForm.module.scss';

interface Props {
  className?: string;
  control: Control<any>; // TODO SZ: any
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  suggestions: (Product & { isInBasket?: boolean | undefined })[];
  isLoading?: boolean;
  selectedItemsNumber?: number;
  onSuggestionClick: (id: string | undefined, isInBasket: boolean) => void;
  onRemoveSelectedItems: () => void;
  onDateChange: (value: Date, slotId?: Slots['id']) => void;
  formValues: CheckoutWizardValues;
  // TODO SZ: create interface
  slots: {
    __typename?: 'slots';
    id: string;
    date: any;
    label: string;
    type: string;
    slotDayId: string;
    isActive: boolean;
    isValid: boolean;
  }[];
  onGetSlots: (date: Date) => Promise<void>;
  loadingSlots?: boolean;
  errors: {
    products?: any[] | undefined;
    date?: FieldError | undefined;
    slotId?: FieldError | undefined;
  };
  onSubmit: () => void;
  basketProducts?: { id: string; product: Product }[];
}

const DesktopSearchForm: React.FC<Props> = ({
  control,
  searchValue,
  onSearchValueChange,
  suggestions,
  isLoading,
  onSuggestionClick,
  onRemoveSelectedItems,
  onDateChange,
  formValues,
  slots,
  onGetSlots,
  loadingSlots,
  errors,
  onSubmit,
  basketProducts = [],
}) => {
  const getDateChangeHandler = (field: { onChange: (value: Date) => void }) => (value: Date, slotId?: Slots['id']) => {
    field.onChange(value);
    onDateChange(value, slotId);
  };

  return (
    <form className={styles.root} onSubmit={onSubmit}>
      <Controller
        control={control}
        name="products"
        render={() => (
          <SearchBox
            className={styles.searchBox}
            value={searchValue}
            onChange={onSearchValueChange}
            suggestions={suggestions}
            suggestionsMenuClass={styles.suggestionsMenu}
            isLoading={isLoading}
            selectedItemsNumber={basketProducts.length}
            onSuggestionClick={onSuggestionClick}
            onRemoveSelectedItems={onRemoveSelectedItems}
            showAllResearchesBtn
          />
        )}
      />
      <div className={styles.datePicker}>
        <Controller
          control={control}
          name="date"
          render={({ field }) => (
            <DateChooser
              placeholderText="Дата и время забора"
              value={field.value}
              onChange={getDateChangeHandler(field)}
              slots={slots}
              onGetSlots={onGetSlots}
              loadingSlots={loadingSlots}
              slotId={formValues?.slotId}
              isError={Boolean(errors.date)}
            />
          )}
        />
      </div>

      <Button className={styles.checkoutBtn} size="large-plus" disabled={basketProducts.length === 0} type="submit">
        <Text size={'large'} inline>
          Перейти к оформлению
        </Text>
      </Button>
    </form>
  );
};

export default DesktopSearchForm;
