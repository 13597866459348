import classNames from 'classnames';

import { benefitsList } from '../../constants';
import Benefit from './Benefit/Benefit';
import styles from './Benefits.module.scss';

const containerClass = 'container-wide';

const Benefits = () => (
  <div className={classNames(styles.root, containerClass)}>
    {benefitsList.map(({ id, icon, title, text }) => (
      <Benefit key={id} icon={icon} title={title} text={text} />
    ))}
  </div>
);

export default Benefits;
