import Title from '@components/Title';
import Text from '@components/Text';
import PartnersCards from '@components/PartnersCards';
import styles from './Partners.module.scss';

const Partners = () => (
  <div className={styles.root}>
    <Title level={1}>Наши партнёры</Title>

    <div className={styles.description}>
      <Text size="extra-large" muted>
        {'Ведущие лаборатории России. Все исследования соответствуют европейскому стандарту ISO-8493.'}
      </Text>
    </div>
    <PartnersCards />
  </div>
);

export default Partners;
