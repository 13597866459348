import Image from 'next/image';
import classNames from 'classnames';

import Title from '@components/Title';
import Text from '@components/Text';

import styles from './StepCard.module.scss';

interface StepCardProps {
  imageSrc: string;
  imagePosition?: 'left' | 'right';
  title: string;
  text: string;
  stepNumber: number;
}

const StepCard: React.FC<StepCardProps> = ({ imageSrc, imagePosition = 'left', title, text, stepNumber }) => {
  const orderIsReverse = imagePosition === 'right';

  return (
    <article className={classNames(styles.root, orderIsReverse && styles.reverseOrder)}>
      <section className={styles.text}>
        <Text className={styles.stepNumber} size={'extra-large'} inline>{`0${stepNumber}`}</Text>
        <Title level={1} className={styles.title}>
          {title}
        </Title>
        <Text className={styles.description} size={'extra-large'}>
          {text}
        </Text>
      </section>
      <section className={styles.image}>
        <Image alt={title} src={imageSrc} width={427} height={427} layout="responsive" />
      </section>
    </article>
  );
};

export default StepCard;
