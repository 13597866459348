import classNames from 'classnames';

import { Page } from '@customTypes/page';
import Layout from '@layouts/CommonLayout';
import { Benefits, ClientsFeedback, Header, HeroSection, ResultSteps, Partners } from '@modules/home/components';
import { Divider, Footer, PopularAnalysis } from '@components/index';
import styles from '@styles/pages/Home.module.scss';

const containerClass = 'container-wide';
const containerFullClass = 'container-full-width';

const Home: Page = () => (
  <div className={styles.root}>
    <section className={classNames(styles.heroSection, containerClass)}>
      <HeroSection />
    </section>

    <section className={classNames(styles.popularAnalysis, containerClass)}>
      <PopularAnalysis />
    </section>

    <section className={styles.benefits}>
      <div className={classNames(styles.benefitsSectionContent, containerFullClass)}>
        <Benefits />
      </div>
    </section>

    <section className={classNames(styles.resultSteps, containerClass)}>
      <ResultSteps />
    </section>

    <Divider className={classNames(styles.divider, containerClass)} />

    <section className={classNames(styles.partners, containerClass)}>
      <Partners />
    </section>

    <Divider className={classNames(styles.divider, containerClass)} />

    <section className={classNames(styles.clientsFeedback, containerClass)}>
      <ClientsFeedback />
    </section>
  </div>
);

Home.getLayout = function getLayout(page) {
  return (
    <Layout headerComponent={Header} footerComponent={Footer} isHeaderFullWidth={false}>
      {page}
    </Layout>
  );
};

export default Home;
