import Link from 'next/link';
import cn from 'classnames';

import { Button, Logo } from '@components/index';
import Text from '@components/Text';

import useMainLabels from '@hooks/useMainLabels';
import { useBasket } from '@hooks/useBasket';
import { useProfile } from '@modules/profile/hooks/useProfile';
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { CrossCircleIcon, SearchIcon, BasketLeftIcon, UserIcon, PhoneHeaderIcon } from '@components/Icon';
import Input from '@components/Input';
import { CONTACTS_PHONE } from '@constants/index';
import useDeviceType from '@hooks/useDeviceType';
import ProfileName from '@components/ProfileName';
import { useAuthModal } from '@modules/auth/AuthContext';

import styles from './Header.module.scss';

const Header: React.FC<{ className?: string }> = ({ className }) => {
  const router = useRouter();

  const { mainLabelsForSearch } = useMainLabels();
  const { basket } = useBasket();
  const { profile, isUnauthorized } = useProfile();
  const { screenWidth } = useDeviceType();
  const { onModalOpen } = useAuthModal();

  const [searchValue, setSearchValue] = useState<string>(router?.query?.search ? String(router?.query?.search) : '');
  const basketCount = basket?.products.length || 0;

  const handleChangeSearchInput = (elem: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(elem.target.value);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { sub_category, ...others } = router.query;
    router.push(
      {
        query: { ...others, category: '1', search: elem.target.value || '' },
      },
      undefined,
      { scroll: false }
    );
  };

  const handleClearSearchValue = () => {
    setSearchValue('');
    router.push({
      query: { ...router.query, search: '' },
    });
  };

  const containerClass = 'container-full-width';

  return (
    <header className={cn(styles.root, containerClass)}>
      <nav className={cn(styles.navBar, className)}>
        <div className={styles.logo}>
          <Logo />
        </div>
        {router?.route === '/search' && (
          <ul>
            <div className={styles.search}>
              <span className={styles.searchIcon}>
                <SearchIcon />
              </span>
              <Input
                className={styles.searchInput}
                value={String(searchValue)}
                placeholder="Поиск исследований"
                onChange={handleChangeSearchInput}
              />
              {!!searchValue.length && (
                <Button theme="ghost" className={styles.searchBtn} onClick={handleClearSearchValue}>
                  <CrossCircleIcon />
                </Button>
              )}
            </div>
          </ul>
        )}
        <ul className={styles.linksList}>
          {!!mainLabelsForSearch.length &&
            // <div className={s.scrollButtons}>
            mainLabelsForSearch.map((item) => {
              const isActive = router?.query.category === item.id;

              return (
                <Link href={item.id ? `/search?category=${item.id}` : '/search'} key={item.id}>
                  <li key={item.id} className={cn(isActive ? styles.active : '')}>
                    <Link href={`/search?category=${item.id}`}>
                      <a>
                        <Text size="medium" lineInherit={true}>
                          {item.title}
                        </Text>
                      </a>
                    </Link>
                  </li>
                </Link>
              );
            })}
        </ul>
        <ul className={styles.iconsList}>
          <li>
            <a href={`tel:${CONTACTS_PHONE}`}>
              <PhoneHeaderIcon />
            </a>
          </li>

          <li>
            <Link href={'/basket'}>
              <a style={{ position: 'relative' }}>
                {basketCount > 0 ? <span className={styles.basketCount}>{basketCount}</span> : null}
                <BasketLeftIcon />
              </a>
            </Link>
          </li>

          <li className={cn(!!profile?.nameLast && !isUnauthorized ? styles.authorized : '')}>
            {!!profile?.nameLast && !isUnauthorized && (
              <Link href={'/profile'} passHref>
                <ProfileName profile={profile} screenWidth={screenWidth} />
              </Link>
            )}
            {!profile?.nameLast && !isUnauthorized && (
              <Link href={'/profile'} passHref>
                <UserIcon />
              </Link>
            )}
            {isUnauthorized && (
              <a onClick={onModalOpen}>
                <UserIcon />
              </a>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
