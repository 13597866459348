import Title from '@components/Title';
import Text from '@components/Text';

import styles from './Benefit.module.scss';

interface BenefitProps {
  icon: React.ComponentType | string;
  title: string;
  text: string;
}

const Benefit: React.FC<BenefitProps> = ({ icon: Icon, title, text }) => (
  <div className={styles.root}>
    <Icon />
    <Title className={styles.title} level={1}>
      {title}
    </Title>
    <Text className={styles.text} size={'large'}>
      {text}
    </Text>
  </div>
);

export default Benefit;
