import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';

import { useCheckoutWizard } from '@modules/checkout/CheckoutWizardContext';

import useSearch from '@hooks/useSearch';
import { useDebouncedCallback } from 'use-debounce';
import { useBasket } from '@hooks/useBasket';
import useSlots from '@hooks/useSlots';
import { useProfile } from '@modules/profile/hooks/useProfile';
import isValidLabs from '@utils/isValidLabs';
import { HeroFormValues } from '@modules/checkout/heroFormSchema';
import { Slots } from '@graphql/generated';
import { useAuthModal } from '@modules/auth/AuthContext';
import { SCREEN } from '@constants/screen';
import useDeviceType from '@hooks/useDeviceType';
import { TODAY_RESETED_TIME } from '@components/DateChooser/constants';

import DesktopSearchForm from './DesktopSearchForm/DesktopSearchForm';
import MobileSearchForm from './MobileSearchForm/MobileSearchForm';
import { setHours } from 'date-fns';

const SearchForm = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { push } = useRouter();
  const { onFindProducts, products, loadingSearch } = useSearch();
  const { isUnauthorized } = useProfile();
  const { basket, onAddToBasket, onRemoveProductFromBasket, onEmptyBasket } = useBasket();
  const { onModalOpen } = useAuthModal();
  const { screenWidth } = useDeviceType();

  const { formValues, handleStepSubmit } = useCheckoutWizard();
  const { onGetSlots, slots, loadingSlots } = useSlots();

  useEffect(() => {
    onGetSlots(TODAY_RESETED_TIME);
  }, [onGetSlots]);

  const {
    getValues,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<HeroFormValues>({});

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    handleFindProductsDebounced(value);
  };

  const handleFindProductsDebounced = useDebouncedCallback(async (value: string) => {
    setIsLoading(true);
    await onFindProducts(String(value));
    setIsLoading(false);
  }, 500);

  const handleDateChange = (value: Date, slotId?: Slots['id']) => {
    const resetedTimeDate = setHours(new Date(value), 0);
    onGetSlots(resetedTimeDate);

    if (slotId) {
      setValue('slotId', slotId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
    }
  };

  const handleFormSubmit = () => {
    handleStepSubmit({ date: getValues('date'), slotId: getValues('slotId') });

    if (isUnauthorized) {
      onModalOpen();
    } else {
      push('/checkout');
    }
  };

  const handleProductClick = (id: string | undefined, isInBasket: boolean) => {
    if (!id) {
      return;
    }

    if (isInBasket) {
      onRemoveProductFromBasket(id);
    } else {
      onAddToBasket(id);
    }
  };

  const handleRemoveSelectedItems = () => {
    onEmptyBasket();
  };

  const SearchFormComponent = screenWidth >= SCREEN.MOBILE_LG_BREAKPOINT ? DesktopSearchForm : MobileSearchForm;

  return (
    <SearchFormComponent
      control={control}
      searchValue={searchValue}
      onSearchValueChange={handleSearchValue}
      suggestions={products.filter((product) => isValidLabs(product)).map((product) => ({ ...product }))}
      isLoading={isLoading || loadingSearch}
      onSuggestionClick={handleProductClick}
      onRemoveSelectedItems={handleRemoveSelectedItems}
      onDateChange={handleDateChange}
      formValues={formValues}
      slots={slots}
      onGetSlots={onGetSlots}
      loadingSlots={loadingSlots}
      errors={errors}
      onSubmit={handleSubmit(handleFormSubmit)}
      basketProducts={basket?.products}
    />
  );
};

export default SearchForm;
