import { useState } from 'react';
import classNames from 'classnames';

import { Button, Quote } from '@components/index';
import Title from '@components/Title';
import Text from '@components/Text';
import { ArrowRightIcon } from '@components/Icon';

import styles from './ClientsFeedback.module.scss';

const feedbackList = [
  {
    id: 0,
    text: 'Сдаю анализы не часто, но стараюсь следить за здоровьем. Всё идет в онлайн и анализы не исключение. Сервис ЛабКлик очень удобен, быстро выбрала и быстро заказала. Тактичная и пунктуальная медсестра сделала процедуру комфортной. Всё заняло не более 7 минут.',
    author: {
      name: 'Александра Белых',
      post: 'клиент',
      avatarSrc: '/images/client-avatar.svg',
    },
  },
  // {
  //   id: 0,
  //   text: 'Заказывала забор крови сразу на семью: себе, деткам, мужу. Очень удобно! Справились за 20 минут, результаты пришли на почту. Медсестра Татьяна была очень вежливой и угостила детей леденцами.',
  //   author: 'Елена, 36 лет',
  // },
  // {
  //   id: 1,
  //   text: 'Когда брали кровь из пальца, не было бо-бо',
  //   author: 'Василий, 43 годика',
  // },
  // {
  //   id: 2,
  //   text: 'После взятия анализов моё зрение восстановилось до единицы на обоих глазах, хотя я родился слепым. Рекомендую!',
  //   author: 'Патологический Врунишка, 189 лет',
  // },
  // {
  //   id: 3,
  //   text: 'А отзывы тут точно не проплачены?',
  //   author: 'Геннадий, что-то подозревает',
  // },
];

const ClientsFeedback = () => {
  const [feedbackIndex, setFeedbackIndex] = useState(0);

  const incrementFeedbackIndex = () =>
    setFeedbackIndex((prevIndex) => (feedbackIndex + 1 < feedbackList.length ? prevIndex + 1 : 0));
  const decrementFeedbackIndex = () =>
    setFeedbackIndex((prevIndex) => (feedbackIndex > 0 ? prevIndex - 1 : feedbackList.length - 1));

  return (
    <div className={styles.root}>
      <Title level={3} className={styles.title} muted>
        Как это было
      </Title>

      <Quote key={feedbackList[feedbackIndex].id} className={styles.quote} author={feedbackList[feedbackIndex].author}>
        {feedbackList[feedbackIndex].text}
      </Quote>

      {feedbackList.length > 1 && (
        <div className={styles.switcher}>
          <Button
            theme="ghost"
            size="small"
            className={classNames(styles.icon, styles.iconLeft)}
            onClick={decrementFeedbackIndex}
          >
            <ArrowRightIcon />
          </Button>
          <Text size="large">
            {feedbackIndex + 1}/{feedbackList.length}
          </Text>
          <Button theme="ghost" size="small" className={styles.icon} onClick={incrementFeedbackIndex}>
            <ArrowRightIcon />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ClientsFeedback;
