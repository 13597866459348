import { useState } from 'react';
import { Controller, Control, FieldError } from 'react-hook-form';
import { formatInTimeZone } from 'date-fns-tz';

import { BottomBar, Button, DateChooserContent, SearchBox } from '@components/index';
import Text from '@components/Text';

import { CheckoutWizardValues } from '@modules/checkout/CheckoutWizardContext';
import { Product, Slots } from '@graphql/generated';
import Overlay from '@components/Overlay/Overlay';
import { ArrowLeftRedesignIcon, SearchIcon } from '@components/Icon';
import pluralizeText, { PluralForms } from '@utils/formatPluralText';

import styles from './MobileSearchForm.module.scss';

interface Props {
  className?: string;
  control: Control<any>; // TODO SZ: any
  searchValue: string;
  onSearchValueChange: (value: string) => void;
  suggestions: (Product & { isInBasket?: boolean | undefined })[];
  isLoading?: boolean;
  onSuggestionClick: (id: string | undefined, isInBasket: boolean) => void;
  onRemoveSelectedItems: () => void;
  onDateChange: (value: Date, slotId?: Slots['id']) => void;
  formValues: CheckoutWizardValues;
  // TODO SZ: create interface
  slots: {
    __typename?: 'slots';
    id: string;
    date: any;
    label: string;
    type: string;
    slotDayId: string;
    isActive: boolean;
    isValid: boolean;
  }[];
  onGetSlots: (date: Date) => Promise<void>;
  loadingSlots?: boolean;
  errors: {
    products?: any[] | undefined;
    date?: FieldError | undefined;
    slotId?: FieldError | undefined;
  };
  onSubmit: () => void;
  basketProducts?: { id: string; product: Product }[];
}

const MobileSearchForm: React.FC<Props> = ({
  control,
  searchValue,
  onSearchValueChange,
  suggestions,
  isLoading,
  onSuggestionClick,
  onRemoveSelectedItems,
  onDateChange,
  formValues = {},
  slots,
  onGetSlots,
  loadingSlots,
  onSubmit,
  basketProducts = [],
}) => {
  const [overlayIsOpen, setOverlayIsOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const dataIsFilled = basketProducts.length !== 0 && formValues.slotId;

  const handleOverlayOpen = () => {
    setStep(0);
    setOverlayIsOpen(true);
  };

  const handleOverlayClose = () => {
    setOverlayIsOpen(false);
  };

  const getDateChangeHandler = (field: { onChange: (value: Date) => void }) => (value: Date, slotId?: Slots['id']) => {
    field.onChange(value);
    onDateChange(value, slotId);
  };

  const handleIncrementStep = () => {
    setStep((prevIndex) => prevIndex + 1);
  };

  const getInfoText = () => {
    if (dataIsFilled) {
      const timeLabel = formatInTimeZone(formValues.date || new Date(), 'Europe/Moscow', 'dd.MM.yyyy');
      const slotLabel = slots.find(({ id }) => id === formValues.slotId)?.label;

      return `${pluralizeText(basketProducts.length, {
        [PluralForms.one]: 'исследование',
        [PluralForms.few]: 'исследования',
        [PluralForms.many]: 'исследований',
      })}, ${timeLabel} в ${slotLabel}`;
    }

    return 'выбрать исследования, дату и время забора';
  };

  return (
    <>
      <Button className={styles.root} theme="ghost" onClick={handleOverlayOpen}>
        <SearchIcon />
        <div className={styles.info}>
          <Text size="large-plus">Оформить заказ</Text>
          <Text muted size="extra-small" uppercase inline fontgraphik>
            {getInfoText()}
          </Text>
        </div>
      </Button>

      {dataIsFilled && (
        <Button className={styles.checkoutBtn} size="large" disabled={basketProducts.length === 0} onClick={onSubmit}>
          <Text size="large" inline>
            Перейти к оформлению
          </Text>
        </Button>
      )}

      <Overlay isOpen={overlayIsOpen} onClose={handleOverlayClose}>
        <BottomBar isOpen={overlayIsOpen} onClose={handleOverlayClose}>
          <form className={styles.form} onSubmit={onSubmit}>
            {step === 0 && (
              <>
                <Controller
                  control={control}
                  name="products"
                  render={() => (
                    <SearchBox
                      className={styles.searchBox}
                      value={searchValue}
                      onChange={onSearchValueChange}
                      suggestions={suggestions}
                      suggestionsMenuClass={styles.suggestionsMenu}
                      isLoading={isLoading}
                      selectedItemsNumber={basketProducts.length}
                      onSuggestionClick={onSuggestionClick}
                      onRemoveSelectedItems={onRemoveSelectedItems}
                      placeholder="Поиск исследований"
                    />
                  )}
                />

                <Button
                  className={styles.checkoutBtn}
                  size="large-plus"
                  disabled={basketProducts.length === 0}
                  onClick={handleIncrementStep}
                >
                  <Text size="large" inline>
                    Выбрать дату и время забора
                  </Text>
                </Button>
              </>
            )}

            {step === 1 && (
              <>
                <Button
                  theme="ghost"
                  size="small"
                  icon={<ArrowLeftRedesignIcon />}
                  className={styles.backButton}
                  onClick={handleOverlayOpen}
                >
                  Назад
                </Button>

                <Text className={styles.analysisList} size="large-plus" muted>
                  Выбрано: {basketProducts.map(({ product }) => product.title).join(', ')}
                </Text>

                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => (
                    <DateChooserContent
                      selectedDate={field.value}
                      slots={slots}
                      selectedSlotId={formValues?.slotId}
                      isLoadingSlots={loadingSlots}
                      onSubmit={getDateChangeHandler(field)}
                      onGetSlots={onGetSlots}
                      submitOnChange
                    />
                  )}
                />

                <Button
                  className={styles.checkoutBtn}
                  size="large-plus"
                  disabled={basketProducts.length === 0}
                  type="submit"
                >
                  <Text size="large" inline>
                    Перейти к оформлению
                  </Text>
                </Button>
              </>
            )}
          </form>
        </BottomBar>
      </Overlay>
    </>
  );
};

export default MobileSearchForm;
