import { Divider } from '@components/index';
import StepCard from '@modules/home/components/ResultSteps/StepCard/StepCard';

import { resultStepsList } from '../../constants';
import styles from './ResultSteps.module.scss';

const ResultSteps = () => (
  <div className={styles.root}>
    {resultStepsList.map(({ id, imageSrc, title, text }, index) => (
      <div key={id}>
        <StepCard
          imageSrc={imageSrc}
          title={title}
          text={text}
          imagePosition={index % 2 === 0 ? 'left' : 'right'}
          stepNumber={index + 1}
        />

        {index + 1 !== resultStepsList.length && <Divider className={styles.divider} />}
      </div>
    ))}
  </div>
);

export default ResultSteps;
